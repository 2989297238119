<template>
  <el-container class="workbench-w" ref="homePage" >
    <el-header>
      <top-nav :userInfo="userInfo"></top-nav>
    </el-header>
    <el-container class="workbench-con">
      <!-- 侧边栏 -->
      <!-- <el-aside>
        <Aside></Aside>
      </el-aside> -->
      <!-- 主体内容 -->
      <el-main >
        <router-view></router-view>
      </el-main>
      
    </el-container>
  </el-container>
</template>
<script>
import TopNav from "@/components/context/TopNav";
// import Aside from "@/components/context/Aside";
export default {
  components: {
    TopNav,
    // Aside,
    
  },
  data() {
    return {
      userInfo: "",
      clientHeight:0,
    };
  },
  created(){
    let userJsonStr = localStorage.getItem('centerUser');
    let userEntity = JSON.parse(userJsonStr);
    this.userInfo = userEntity;
    // console.log(userEntity);

    // let userInfoStr = sessionStorage.getItem('user_info');
    // let userInfo = JSON.parse(userInfoStr);
    // console.log(userInfo);
  },
  mounted(){
      // 获取浏览器可视区域高度
      // window.innerHeight
      // this.clientHeight =   `${document.documentElement.clientHeight}`
      console.log(window.innerHeight)
      this.clientHeight = window.innerHeight;  
      // this.$refs.homePage.$el.style.height = this.clientHeight;     
      // console.log('this.clientHeigh',  this.clientHeight)   
      // console.log('this.$refs.homePage.$el.style.height', this.$refs.homePage.$el.style.height); 
      //document.body.clientWidth;
      //console.log(self.clientHeight);
      window.onresize = function temp() {
        // this.clientHeight = `${document.documentElement.clientHeight}`;
        this.clientHeight = window.innerHeight;
        console.log(1)
      };
    },
    watch: {
      // 如果 `clientHeight` 发生改变，这个函数就会运行
      clientHeight: function () {
        // console.log(2, this.clientHeight)
        this.changeFixed(this.clientHeight)
      }
    },

    methods:{

      changeFixed(clientHeight){ //动态修改样式
        // console.log('clientHeight',clientHeight);
        // console.log(this.$refs.homePage.$el.style.height);
        this.$refs.homePage.$el.style.height = clientHeight+60+'px';
      },
    },
};
</script>
<style scoped lang="scss">
.workbench-w {
  width: 100%;
  min-height: 100vh;
  .el-header {
    padding: 0;
  }
  .workbench-con {
    
    display: flex;
    height: 100%;
    .el-aside {
      width: 64px !important;
      background: linear-gradient(360deg,#26304d 0%,#1f2740 29%,#3c4b6c 100%);
    }

    /deep/ .el-main{
      padding: 0px !important;
      // padding-bottom: 100px !important;
      
    }
    // .workbench-main {
    //   flex: 1;
    //   .el-header {
    //     height: 40px;
    //     background-color: #eee;
    //     line-height: 40px;
    //     border-bottom: 1px solid #eee;
    //     .cur-nav {
    //       width: 181px;
    //       height: 100%;
    //       background: #f8f9fa;
    //       border-radius: 8px 8px 0px 0px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       font-size: 14px;
    //       font-weight: 500;
    //       color: #1e1e1e;
    //       position: relative;
    //       img {
    //         width: 30px;
    //         margin-right: 10px;
    //       }
    //       i {
    //         position: absolute;
    //         right: 8px;
    //         font-size: 20px;
    //         color: rgba(0, 0, 0, 0.4);
    //         cursor: pointer;
    //       }
    //     }
    //   }


      
    // }
  }
}
</style>
