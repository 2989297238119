<template>
  <div class="workbench-w">
    <el-header>
      <img class="islogo" src="../../../public/img/logo_01.png" alt="">
      <!-- <div class="search">
        <i class="el-icon-search"></i>
        <el-input
          placeholder="输入需要搜索的功能模块"
          v-model="input"
          clearable
        ></el-input>
      </div> -->
      <div class="welcom">Hi,{{userInfo.personnelName}}，欢迎登录  
        <el-button round size='mini' style="margin-left:10px" @click="outLogin">退出登录</el-button>
      </div>
    </el-header>
  </div>
</template>
<script>
  import {
    logout
  } from '@/api/login/index.js';
export default {
  props:{
    userInfo:[Array,Object,String]
  },
  data() {
    return {
      input:'',
    };
  },
   methods:{
      outLogin() {
        this.$confirm("是否退出系统, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          const res =  logout()
          console.log(res)
          // sessionStorage.getItem('access_token')
          window.localStorage.removeItem('access_token')
          this.$router.push({ path: "/login" });
        });
      }
    }
};
</script>
<style scoped lang="scss">
.el-header {
  height: 46px;
  padding: 0;
  background: linear-gradient(360deg, #26304d 0%, #1f2740 29%, #3c4b6c 100%);
  display: flex;
  align-items: center;
  position: relative;
  .search {
    width: 618px;
    height: 36px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    i {
      color: #1e1e1e;
      margin: 0 13px;
    }
    .el-input {
      flex: 1;
      font-size: 14px;
      /deep/ .el-input__inner {
        background-color: transparent;
        border: none;
        padding: 0;
        color: #1e1e1e;
        &::placeholder {
          color: #1e1e1e;
        }
      }
    }
  }
  .welcom {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    position: absolute;
    right: 32px;
  }
  .islogo{
    width: 120px;
    margin-left: 20px;
  }
}
</style>
