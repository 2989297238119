import {request} from '@/api/common/requestThird.js';

const sendCodeUrl = "/minapp/login/mobile/send/";//发送验证码
const socialLoginUrl ="auth/mobile/token/social";//验证码登录
const wxCode ="/minapp/login/wxCode";//验证码登录
// sms
// https://sam.wis.ltd/auth/mobile/token/sms?mobile=SMS@15928046651&grant_type=mobile&code=5209
export function sendCode(mobile){
  return request({
    url:sendCodeUrl+mobile,
    method:'GET',
  })
}

export function socialLogin(data){
  return request({
    url:socialLoginUrl + '?mobile=' + data.mobile + '&grant_type=' + data.grant_type + '&code='+ data.code,
    method:'post',
    data
  })
}


export function logout(){
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}


export function getWxCode(){
  return request({
    url: wxCode,
    method: 'get'
  })
}
