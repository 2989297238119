import axios from 'axios'
import { serialize } from '@/util/util'
import { Message } from 'element-ui'
// const baseURL ="http://192.168.0.150:9999/"
// const baseURL = "http://31094ld435.wicp.vip/"
// const method = "POST"

export function request(config){
  let baseURL = window.localStorage.getItem('ipUrl')
  const instance = axios.create({
    baseURL,
    timeout:5000,
    // method,
  })


  //请求拦截
  instance.interceptors.request.use(config => {
    let token = localStorage.getItem('access_token')
    config.headers.isToken = false;
    config.headers['TENANT-ID'] = 1;
    config.headers.Authorization = 'Basic bWluYXBwOm1pbmFwcA==';
    if(token){
      if(config.url.indexOf('auth/mobile/token/social') != -1 ) {
        config.headers.Authorization = 'Basic bWluYXBwOm1pbmFwcA==';
      }else{
        config.headers.Authorization = 'Bearer '+token;
      }
    }
    if (config.method == 'post') {
      config.data = serialize(config.data)
      delete config.data.serialize
    }

    return config
  },err =>{
    console.log(err);
  })

  // 响应拦截
  instance.interceptors.response.use(res =>{
    console.log('响应结果', res);
    if(res.status != 200){
      return false;
    }
   
    return res.data
  },err => {
    if(err.request.status == 428) {
      let message = JSON.parse(err.request.response).msg
      Message.error(message)
      return
    }
    if(err.request.status == 424) {
      Message.error('令牌状态已过期，请点击重新登录')
      return
    }
    Message.error('网络错误，请重新配置');
  })


  let method = config.method ? config.method.toLocaleLowerCase() : 'get';
  // console.log(method);
  
  if (method == 'get') {
    config.params = config.data
  } else {
    // config.data = config.data
  }
  // 发送真正的网络请求
  let res = instance(config);
  return res;


}
